<template>
<Navbar :user="user" />
    <section class="courses-area" v-if="!isLoading">
      <div class="container">
          <div class="courses-topbar">
            <div class="row align-items-center">
              <h4 class="text-center">Courses in {{ category.name }}</h4>
            </div>
          </div>
          <div class="shadow-lg pt-5">
          <div  v-if="courses.length">
            <div class="row mb-30">
              <div class="col-lg-2 col-md-2 mix business design language mb-30 mt-30 me-30 ms-3" v-for="course in courses" :key="course.id">
                <SingleCourse :course="course"/>
              </div>
            </div>
          </div>
          <div class="pt-100 pb-100" v-else>
            <h5>No Courses Available</h5>
          </div>
        </div>
      </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import SingleCourse from '@/components/SingleCourse.vue'
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
export default {
  components: { 
    Navbar, SingleCourse, Loading
  },
  data(){
    return {
      isLoading: true,
      token: localStorage.getItem('user_data'),
      errors: {},
      courses: {},
      category:{},
      user:{}
    }
  },
  watch: {
    "$route.params.id": {
      handler: function() {
        this.isLoading =  true
        this.getCourses()
        this.getCategory()
      },
      deep: true,
      immediate: true,
    },
  },
  methods:{
    getCourses(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/course/get_courses_by_category/' + id ).then(response => {
          this.courses = response.data
      }).catch(errors => {
          this.errors = errors.response.data.errors
      }).finally(() => {
          setTimeout(function () {
          this.isLoading =  false
          }.bind(this), 500);
      })
    },
    getCategory(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/course/get_single_category/' + id ).then(response => {
          this.category = response.data
      }).catch(errors => {
          this.errors = errors.response.data.errors
      })
    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
          this.user = response.data
      }).catch(errors => {
         this.authError = errors.response.data.errors
      })
    },
  },
  created(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getCourses() 
    this.getCategory()
    this.getUser()
  }
}
</script>

<style>

</style>